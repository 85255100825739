import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { utils, writeFile } from 'xlsx';
import loadingGif from '../loading.gif'; 
import { FaFilePdf, FaFileExcel, FaPrint } from 'react-icons/fa';
import moment from 'moment'
// import Dateformat from '../formatdate';
// import { localhost } from './env.js';

const Report = ({ state, setState }) => {
  const {localhost,sesdate}= state || 'localhost:3000';
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(sesdate);
  const [endDate, setEndDate] = useState(sesdate);
  const branch=state.branch.slice(0,3);

  const buttonStyle = (num = 0) => ({
    border: '2px solid #331818',
    boxShadow: '0 0 10px rgba(0, 50, 0, 0.1)',
    maxWidth: '50%',
    display: 'flex',
    marginRight: '1%',
    marginLeft: num === 1 ? '10%' : '1%',
    borderRadius: '30%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '10px',
    backgroundColor: num === 0 ? 'white' : num === 1 ? 'yellow' : 'red',
  });

  const buttonContainer = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '1%',
  };

  const printReport = () => {
    const reportContainer = document.querySelector('.report-container');
    const newWindow = window.open('', '_blank');
    
    newWindow.document.write(`
      <html>
        <head>
          <title>Print Report</title>
          <style>
            body { font-family: Arial, sans-serif; }
            .report-table { width: 100%; border-collapse: collapse; }
            .report-table th, .report-table td { border: 1px solid #ddd; padding: 4px; }
            .report-table th { background-color: #f0f0f0; }
            @media print {
              body { font-size: 10px; } /* Adjust the font size for printing */
              .report-table th, .report-table td { padding: 2px; } /* Optional: Reduce padding for a more compact table */
            }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          <div>${reportContainer.innerHTML}</div>
        </body>
      </html>
    `);
    
    newWindow.document.close();
  };
  const handleDownloadPDF = () => {
    const doc = new jsPDF('l');
    doc.text('Daily Transaction Report', 10, 10);
    
    const columns = [
      { header: 'Branch', dataKey: 'Branch' },
      { header: 'Primary Officer ID', dataKey: 'primaryofficerid' },
      { header: 'Loan Balance', dataKey: 'Loanbal' },
      { header: 'Deposit Balance', dataKey: 'DepositBal' },
      { header: 'Disbursement', dataKey: 'disbursement' },
      { header: 'Mobilized', dataKey: 'mobilized' },
      { header: 'Overdue Interest', dataKey: 'OVAPLusInt' },
      { header: 'Overdue Principal Only', dataKey: 'OVAprinOnly' },
      { header: 'Borrower', dataKey: 'borrower' },
      { header: 'New Client', dataKey: 'newclient' },
      { header: 'Closed Client', dataKey: 'closedclient' },
      { header: 'New Borrower', dataKey: 'NBorrower' },
      { header: 'Full Payment', dataKey: 'fullpay' },
      { header: 'BOD', dataKey: 'BOD' },
      { header: 'PAR', dataKey: 'PAR' },
    ];

    doc.autoTable({
      head: [columns.map(col => col.header)],
      body: data.map(item => columns.map(col => item[col.dataKey])),
      startY: 20,
    });

    doc.save('Daily_Transaction_Report.pdf');
  };

  const handleDownloadExcel = () => {
    const workbook = utils.book_new();
    const worksheet = utils.json_to_sheet(data);
    utils.book_append_sheet(workbook, worksheet, 'Report');
    writeFile(workbook, 'Daily_Transaction_Report.xlsx');
  };
/// Set initial values for dates
useEffect(() => {
  const today = sesdate; // YYYY-MM-DD format
  setStartDate(today);
  setEndDate(today);
}, []);

//////////////////////handle Display
const fetchTransactions = async () => {
  setLoading(true);
  try {
    const response = await axios.get(`${localhost}/transactionreports`, {
      params: { startDate, endDate, branch } // Send parameters here
    });
    setData(response.data);
  } catch (err) {
    setError(err.response ? err.response.data.err : 'An error occurred');
    console.error('Error fetching transactions:', err);
  } finally {
    setLoading(false);
  }
};
  const groupData = () => {
    const grouped = {};
    const grandTotal = {
      '010': 0,
      '001': 0,
      '011': 0,
      '002': 0,
      '005': 0,
      '065': 0,
    };

    data.forEach((row) => {
      const formattedValueDate = new Date(row.ValueDate).toISOString().slice(0, 10);
      const { PrimaryOfficerID, groupid, TranID, Amount } = row;

      if (!grouped[formattedValueDate]) {
        grouped[formattedValueDate] = {};
      }
      if (!grouped[formattedValueDate][PrimaryOfficerID]) {
        grouped[formattedValueDate][PrimaryOfficerID] = {};
      }
      if (!grouped[formattedValueDate][PrimaryOfficerID][groupid]) {
        grouped[formattedValueDate][PrimaryOfficerID][groupid] = {
          rows: [],
          subtotals: {
            '010': 0,
            '001': 0,
            '011': 0,
            '002': 0,
            '005': 0,
            '065': 0,
          },
        };
      }

      grouped[formattedValueDate][PrimaryOfficerID][groupid].rows.push(row);

      switch (row.TranID) {
        case '010':
        case 'R010':
          grouped[formattedValueDate][PrimaryOfficerID][groupid].subtotals['010'] += Amount;
          grandTotal['010'] += Amount;
          break;
        case '001':
        case 'R001':
          grouped[formattedValueDate][PrimaryOfficerID][groupid].subtotals['001'] += Amount;
          grandTotal['001'] += Amount;
          break;
        case '011':
        case 'R011':
          grouped[formattedValueDate][PrimaryOfficerID][groupid].subtotals['011'] += Amount;
          grandTotal['011'] += Amount;
          break;
        case '002':
        case 'R002':
          grouped[formattedValueDate][PrimaryOfficerID][groupid].subtotals['002'] += Amount;
          grandTotal['002'] += Amount;
          break;
        case '005':
        case 'R005':
          grouped[formattedValueDate][PrimaryOfficerID][groupid].subtotals['005'] += Amount;
          grandTotal['005'] += Amount;
          break;
      }
    });

    return { grouped, grandTotal };
  };

  const renderTableData = () => {
    const { grouped, grandTotal } = groupData();
  
    return Object.entries(grouped).map(([valueDate, officerData]) => (
      <React.Fragment key={valueDate}>
        <tr>
          <td colSpan={12} style={{ fontWeight: 'bold' }}>{`Value Date: ${valueDate}`}</td>
        </tr>
        {Object.entries(officerData).map(([officerID, groupData]) => (
          <React.Fragment key={officerID}>
            <tr>
              <td colSpan={12} style={{ fontWeight: 'bold' }}>{`Officer: ${officerID}`}</td>
            </tr>
            {Object.entries(groupData).map(([group, groupDetails]) => (
              <React.Fragment key={group}>
                <tr style={{ fontWeight: 'bold', backgroundColor: '#f9f9f9' }}>
                  <td colSpan={12}>{`Group ID: ${group}`}</td>
                </tr>
                {groupDetails.rows.map((row, index) => (
                  <tr key={index}>
                    <td>{row.TranID}</td>
                    <td>{row.CustNo}</td>
                    <td>{row.AccountID}</td>
                    <td>{row.StmtRef}</td>
                    <td style={{ color: "green" }}>{row.TranID === '010' || row.TranID === 'R010' ? row.Amount.toLocaleString() : '0'}</td>
                    <td style={{ color: "green" }}>{row.TranID === '001' || row.TranID === 'R001' ? row.Amount.toLocaleString() : '0'}</td>
                    <td style={{ color: "green" }}>{row.TranID === '011' || row.TranID === 'R011' ? row.Amount.toLocaleString() : '0'}</td>
                    <td style={{ color: "green" }}>{row.TranID === '002' || row.TranID === 'R002' ? row.Amount.toLocaleString() : '0'}</td>
                    <td style={{ color: "green" }}>{row.TranID === '005' || row.TranID === 'R005' ? row.Amount.toLocaleString() : '0'}</td>
                    <td style={{ color: "green" }}>{row.TranID === '065' || row.TranID === 'R065' ? row.Amount.toLocaleString() : '0'}</td>
                    <td>{row.ValueDate.slice(0,10)}</td>
                  </tr>
                ))}
                <tr style={{ fontWeight: 'bold', backgroundColor: '#e0e0e0' }}>
                  <td colSpan={4}>Subtotal for Group {group}</td>
                  <td style={{ color: "green" }}>{groupDetails.subtotals['010'].toLocaleString()}</td>
                  <td style={{ color: "green" }}>{groupDetails.subtotals['001'].toLocaleString()}</td>
                  <td style={{ color: "green" }}>{groupDetails.subtotals['011'].toLocaleString()}</td>
                  <td style={{ color: "green" }}>{groupDetails.subtotals['002'].toLocaleString()}</td>
                  <td style={{ color: "green" }}>{groupDetails.subtotals['005'].toLocaleString()}</td>
                  <td style={{ color: "green" }}>{groupDetails.subtotals['065'].toLocaleString()}</td>
                  <td></td>
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </React.Fragment>
    ));
  };
  

  const renderTableTotal = (grandTotal) => (
    
      <table>
        <tbody>
          <tr>
           
            <td colSpan={4}><strong>Grand Total</strong></td>
            
            <td style={{ color: "green" }}>{grandTotal['010'].toLocaleString()}</td>
            <td style={{ color: "green" }}>{grandTotal['001'].toLocaleString()}</td>
            <td style={{ color: "green" }}>{grandTotal['011'].toLocaleString()}</td>
            <td style={{ color: "green" }}>{grandTotal['002'].toLocaleString()}</td>
            <td style={{ color: "green" }}>{grandTotal['005'].toLocaleString()}</td>
            <td style={{ color: "green" }}>{grandTotal['065'].toLocaleString()}</td>
            
          </tr>
        </tbody>
      </table>
    
  );

  const { grouped, grandTotal } = groupData();

  if (loading) {
    return <img src={loadingGif} alt="Loading..." style={{ width: '10%', height: '10%' }} />;
  }

  if (error) {
    return <div style={{ color: 'red' }}>{error.replace('mssql-70716-0.cloudclusters.net', 'Database server')}</div>;
  }
  
  const handleChange = (name, value) => {
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
  };
  const datestyle={
    width:"120px",
    display: "block"
    
  };
  return (
    <div className="report-container">
      <div style={{ display: 'flex', alignItems: 'center' }}><label>
        Start Date:
        <input
          type="date"
          name="startDate"
          style={datestyle}
          value={moment(startDate).format('YYYY-MM-DD')}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
        End Date
        <input
          type="date"
          style={datestyle}
          name="endDate"
          value={moment(endDate).format('YYYY-MM-DD')}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />
      </label>
      <button style={datestyle} onClick={fetchTransactions}>Display</button>
      </div>
      <h3 style={{ color: "Gray", textAlign: 'center' }}>Daily Transaction Report</h3>
      <table className="report-table">
        <thead>
          <tr style={{ color: "grey", fontWeight: "bold", backgroundColor: "#f0f0f0" }}>
            <th>TranID</th>
            <th>CustNo</th>
            <th>AccountID</th>
            <th>StmtRef</th>
            <th>Disbursement</th>
            <th>Repayment</th>
            <th>Int</th>
            <th>Deposit</th>
            <th>Withdrawal</th>
            <th>Others</th>
            <th>ValueDate</th>
          </tr>
        </thead>
        <tbody>
          {renderTableData(grouped)} 
          <tr>
           
           <td colSpan={4}><strong>Grand Total</strong></td>
           
           <td style={{ color: "green" }}><strong>{grandTotal['010'].toLocaleString()}</strong></td>
           <td style={{ color: "green" }}><strong>{grandTotal['001'].toLocaleString()}</strong></td>
           <td style={{ color: "green" }}><strong>{grandTotal['011'].toLocaleString()}</strong></td>
           <td style={{ color: "green" }}><strong>{grandTotal['002'].toLocaleString()}</strong></td>
           <td style={{ color: "green" }}><strong>{grandTotal['005'].toLocaleString()}</strong></td>
           <td style={{ color: "green" }}><strong>{grandTotal['065'].toLocaleString()}</strong></td>
           
         </tr> 
        </tbody>
      </table>
      
      <div className="button-container" style={buttonContainer}>
        <FaPrint style={buttonStyle(1)} onClick={printReport} /> Print Report
        <FaFilePdf style={buttonStyle(2)} onClick={handleDownloadPDF} /> Download PDF
        <FaFileExcel style={buttonStyle(0)} onClick={handleDownloadExcel} /> Download Excel
      </div> 
    </div>
  );
};

export default Report;
